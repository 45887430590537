import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import axios from "axios";

const Context = createContext();



function ContextProvider({ children }) {
    const [streamPage, setStreamPage] = useState(null);
    const [streamersList, setStreamers] = useState(null);
    const [partnersList, setPartners] = useState(null);
    const [mediaLinks, setLinks] = useState(null);
    const [content, setContent] = useState(null);
    const [language, setLanguage] = useState(localStorage.getItem("language"));
    const [disposition, setDisposition] = useState("");
    const [winners, setWinners] = useState([]);
    const [slots, setSlots] = useState([]);
    const [modaleType, setModaleType] = useState(null);
    const [user, setUser] = useState(null);
    const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken'));
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));
    const [promocode, setPromocode] = useState(null);
    const [prize, setPrize] = useState(null);
    const [notifiId, setNotifiId] = useState(0);
    const [notifiQueue, setNotifiQueue] = useState([]);
    const [notifiList, setNotifiList] = useState([]);
    const [rollIsActive, setRollIsActive] = useState(false);

    let notifiTimerIsRunned = false;
    const endPoint = process.env.REACT_APP_END_POINT;

    const notifiListRef = useRef(notifiList);
    const notifiQueueRef = useRef(notifiQueue);

    const notifiTypes = {
        successfull: {
            color: "#94F24A",
            title: "successful"
        },
        warning: {
            color: "#F2994A",
            title: "warning"
        },
        error: {
            color: "#DC143C",
            title: "error"
        }
    }


    const startTimer = () => {
        if (notifiTimerIsRunned) return;
        notifiTimerIsRunned = true;
        const interval = setInterval(() => {

            const notifiList_ = notifiListRef.current;

            notifiList_.forEach((el, idx) => {
                if (el.time && el.time > 0) {
                    if (el.isHidden) {
                        notifiList_[idx] = { ...el, isHidden: false, animation: 'addNotifi' }
                    } else {
                        notifiList_[idx] = { ...el, time: (el.time - 500) }
                    }
                } else if (!el.isHidden) {
                    notifiList_[idx] = { ...el, isHidden: true, animation: 'removeNotifi' }
                } else {
                    notifiList_[idx] && removeNotifi(el.id, notifiList_);
                }
            })


            setNotifiList([...notifiList_]);
        }, 500)

        return () => {
            clearInterval(interval);
            notifiTimerIsRunned = false;
        }
    };

    const addNotifi = (obj) => {

        const data = {
            type: obj.type,
            isHidden: true,
            animation: '',
            time: obj.time,
            title: obj.type.title,
            text: obj.text,
            id: notifiId
        }

        const findQueueIDX = notifiQueue.findIndex((el) => el.title === data.title && el.text === data.text);
        const findListIDX = notifiList.findIndex((el) => el.title === data.title && el.text === data.text);
        if (findQueueIDX !== -1 && findListIDX !== -1) return;

        setNotifiId(notifiId + 1);
        setNotifiQueue([...notifiQueue, data]);
    };

    const removeNotifi = (id, list) => {
        let idx = list.findIndex((el) => {
            return el.id === id
        });
        if (idx > -1) {
            list.splice(idx, 1);
            setNotifiList([...list]);
        }

    };

    const requestOptions = {
        params: {
            language_code: language
        },
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    };

    const fillPartners = () => {
        return [
            {
                "name": "sadasd",
                "preview": "https://s0.rbk.ru/v6_top_pics/media/img/7/65/755540270893657.jpg",
                "link": "https://i.pinimg.com/736x/01/eb/25/01eb25fe02e118d04b2b1b10ef7b2f1c.jpg"
            },
            {
                "name": "string",
                "preview": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTIA1nb9FmeDRxItMh73WbDDuz-5w7TfL5-oWnJ2aeKOs4lS7x9t8ik6I7y5YJETX15dHU&usqp=CAU",
                "link": "https://i.pinimg.com/736x/01/eb/25/01eb25fe02e118d04b2b1b10ef7b2f1c.jpg"
            },
            {
                "name": "string",
                "preview": "https://avatars.dzeninfra.ru/get-zen_doc/8098241/pub_641ec1d0798be415157b4180_641ec210d2d45b2f14e36c73/scale_1200",
                "link": "https://i.pinimg.com/736x/01/eb/25/01eb25fe02e118d04b2b1b10ef7b2f1c.jpg"
            },
            {
                "name": "string",
                "preview": "https://s1.bloknot-voronezh.ru/thumb/850x0xcut/upload/iblock/509/0d1587dc21_7605080_8213488.jpg",
                "link": "https://i.pinimg.com/736x/01/eb/25/01eb25fe02e118d04b2b1b10ef7b2f1c.jpg"
            },
            {
                "name": "string",
                "preview": "https://img.freepik.com/free-photo/close-up-on-kitten-standing-on-sofa_23-2150782347.jpg",
                "link": "https://i.pinimg.com/736x/01/eb/25/01eb25fe02e118d04b2b1b10ef7b2f1c.jpg"
            },
            {
                "name": "string",
                "preview": "https://i.pinimg.com/736x/01/eb/25/01eb25fe02e118d04b2b1b10ef7b2f1c.jpg",
                "link": "https://i.pinimg.com/736x/01/eb/25/01eb25fe02e118d04b2b1b10ef7b2f1c.jpg"
            },
        ]
    };
    const fillStreamersList = () => {
        setStreamers([
            {
                "event": "on",
                "name": "testName1",
                "description": "Enjoy a superior vaping experience that allows you to enjoy every breath. Our device delivers a dense and thick vapor, creating an unparalleled vaping experience that will leave you thrilled.",
                "promo": "4554116",
                "stream_link": "HQtFR3mhzOY",
                "youtube": "https://www.youtube.com/watch?v=36YnV9STBqc",
                "instagram": "https://www.instagram.com/reels/audio/3455233191370869/",
                "facebook": "https://uk-ua.facebook.com/",
                "telegram": "https://web.telegram.org/a/",
                "partners": []
            },
            {
                "event": "off",
                "name": "testName2",
                "description": "Enjoy a superior vaping experience that allows you to enjoy every breath. Our device delivers a dense and thick vapor, creating an unparalleled vaping experience that will leave you thrilled.",
                "promo": "65411651",
                "stream_link": "uNd5kvrGHjk",
                "youtube": "https://www.youtube.com/watch?v=36YnV9STBqc",
                "instagram": "https://www.instagram.com/reels/audio/3455233191370869/",
                "facebook": "https://uk-ua.facebook.com/",
                "telegram": "https://web.telegram.org/a/",
                "partners": [
                    {
                        name: "partner1",
                        link: "https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Statements/continue",
                        preview: null,
                    },
                    {
                        name: "partner2",
                        link: "https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Statements/continue",
                        preview: null,
                    }
                ]
            },
            {
                "event": "on",
                "preview": "",
                "name": "testName3",
                "description": "Some description for test2222",
                "promo": "65411651",
                "stream_link": "uNd5kvrGHjk",
                "youtube": "https://www.youtube.com/watch?v=36YnV9STBqc",
                "instagram": "https://www.instagram.com/reels/audio/3455233191370869/",
                "facebook": "https://uk-ua.facebook.com/",
                "telegram": "https://web.telegram.org/a/",
                "partners": [
                    {
                        name: "partner1",
                        link: "https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Statements/continue",
                        preview: null,
                    },
                    {
                        name: "partner2",
                        link: "https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Statements/continue",
                        preview: null,
                    }
                ]
            },
            {
                "event": "off",
                "name": "testName2",
                "description": "Some description for test2222",
                "promo": "65411651",
                "stream_link": "uNd5kvrGHjk",
                "youtube": "https://www.youtube.com/watch?v=36YnV9STBqc",
                "instagram": "https://www.instagram.com/reels/audio/3455233191370869/",
                "facebook": "https://uk-ua.facebook.com/",
                "telegram": "https://web.telegram.org/a/",
                "partners": [
                    {
                        name: "partner1",
                        link: "https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Statements/continue",
                        preview: null,
                    },
                    {
                        name: "partner2",
                        link: "https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Statements/continue",
                        preview: null,
                    }
                ]
            },
            {
                "event": "on",
                "name": "testName4",
                "description": "Some description for test2222",
                "promo": "65411651",
                "stream_link": "uNd5kvrGHjk",
                "youtube": "https://www.youtube.com/watch?v=36YnV9STBqc",
                "instagram": "https://www.instagram.com/reels/audio/3455233191370869/",
                "facebook": "https://uk-ua.facebook.com/",
                "telegram": "https://web.telegram.org/a/",
                "partners": [
                    {
                        name: "partner1",
                        link: "https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Statements/continue",
                        preview: null,
                    },
                    {
                        name: "partner2",
                        link: "https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Statements/continue",
                        preview: null,
                    }
                ]
            }
        ])
    };
    const fillContent = () => {
        setContent(
            {
                en: {
                    preview_title: "WarnMedia \nՁԵՐ ԵՐԵԿՈՅԻ ԼԱՎ ՏՐԱՄԱԴՐՈՒԹՅՈՒՆԸ",
                    preview_text: "ԼԱՎԱԳՈՒՅՆ ԵԹԵՐՆԵՐԸ, ԱՌԱՎԵԼԱԳՈՒՅՆ ԲՈՆՈՒՍՆԵՐԸ ԵՎ ԼԱՎ ՏՐԱՄԱԴՐՈՒՄ",
                    preview_button: "Իմանալ ավելին",
                    streamers_title: "ՄԵՐ ԿԱԶՄԸ",
                    streamers_text: "ՍԱ ՄԵՐ ԱԼԻՔՆԵՐՆ ԵՆ, ՄԵԶ ԴՈՒՐ Է ԳԱԼԻՍ, ՈՐ ԴՈՒՔ ՆԱՅՈՒՄ ԵՔ ՄԵՐ ՍԹՐԻՄՆԵՐԸ",
                    streamers_button: "Իմանալ ավելին",
                    partners_title: "ԿԱՅՔԵՐԸ, ՈՐՏԵՂ ՄԵՆՔ ԽԱՂՈՒՄ ԵՆՔ",
                    partners_text: "ԽԱՂԱՅԻՆ ԿԱՅՔԵՐԻ ԱԿՏՈՒԱԼ ԼԻՆՔԵՐԸ, ԹԱՐՄ ԲՈՆՈՒՍՆԵՐԸ",
                    partners_button: "Իմանալ ավելին",

                },
                ru: {
                    preview_title: "WarnMedia ХОРОШЕE НАСТРОЕНИЯ \nДЛЯ ВАШЕГО ВЕЧЕРА.",
                    preview_text: "ЛУЧШИЕ ЭФИРЫ, ЛУЧШИЕ БОНУСЫ И ВЫГОДНЫЕ ПРЕДЛОЖЕНИЯ",
                    preview_button: "Узнать больше",
                    streamers_title: "Наши партнёры",
                    streamers_text: "ЭТО НАШ КАНАЛ, МЫ ЛЮБИМ, ЧТО ВЫ СМОТРИТЕ НАШИ СТРИМЫ",
                    streamers_button: "Наши партнёры",
                    partners_title: "САЙТЫ, НА которых мы играем",
                    partners_text: "АКТУАЛЬНЫЕ ССЫЛКИ НА ИГРОВЫЕ САЙТЫ, ПОСЛЕДНИЕ БОНУСЫ",
                    partners_button: "САЙТЫ",
                },
                de: {
                    preview_title: "WarnMedia \nGute Stimmung \nFÜR DEINEN ABEND.",
                    preview_text: "BESTE AIRS, BESTE BONI UND TOLLE ANGEBOTE",
                    preview_button: "Um mehr zu lernen",
                    streamers_title: "Unsere Partner",
                    streamers_text: "DAS IST UNSER KANAL, WIR LIEBEN ES, DASS DU UNSEREN STREAM ANSEHST",
                    streamers_button: "Unsere Partner",
                    partners_title: "SEITEN, AUF DENEN WIR SPIELEN",
                    partners_text: "AKTUELLE LINKS ZU GAMING-SEITEN, NEUESTE BONI",
                    partners_button: "WIR SPIELEN",
                }
            }
        )
    };
    const fillLinks = () => {
        return ([
            {
                name: "instagram",
                link: ""
            },
            {
                name: "telegram",
                link: ""
            }
        ])
    };
    const fillSlots = () => {
        setSlots([
            {
                name: "Iphone 15",
                chance: 0.1666666,
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                name: "Iphone 15",
                chance: 0.1666666,
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                name: "Iphone 15",
                chance: 0.1666666,
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                name: "Iphone 15",
                chance: 0.1666666,
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                name: "Iphone 15",
                chance: 0.1666666,
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                name: "Iphone 15",
                chance: 0.1666666,
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                name: "Iphone 15",
                chance: 0.1666666,
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                name: "Iphone 15",
                chance: 0.1666666,
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                name: "Iphone 15",
                chance: 0.1666666,
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            }


        ])
    };
    const fillwinners = () => {
        setWinners([
            {
                nickname: "eVenych",
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                nickname: "eVenych",
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                nickname: "eVenych",
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                nickname: "eVenych",
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                nickname: "eVenych",
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                nickname: "eVenych",
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                nickname: "eVenych",
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                nickname: "eVenych",
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                nickname: "eVenych",
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                nickname: "eVenych",
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                nickname: "eVenych",
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
            {
                nickname: "eVenych",
                img: "https://www.freeiconspng.com/thumbs/iphone-x-pictures/apple-iphone-x-pictures-5.png"
            },
        ])
    };

    const getStreamersList = async () => {
        // fillStreamersList();
        try {
            const response = await axios.get(`${endPoint}/api/streamers/`);
            if ('error' in response.data) {
                alert(response.data.error);
                return
            }
            setStreamers(response.data)
        } catch (error) {
            alert(error.message);
        }
    };
    const getStreamerPage = async (name) => {

        const partners = [];
        try {
            const response = await axios.get(`${endPoint}/api/streamers/${name}`);
            if ('error' in response.data) {
                alert(response.data.error);
                return
            }
            setStreamPage(response.data)
            if (response.data.partners.length > 0) {
                for (let i = 0; i < 6;) {
                    response.data.partners.map((partner) => {
                        partners.push(partner)
                        i++
                    })
                }
                document.documentElement.style.setProperty('--partners-list-size', `${partners.length * 20}vw`);
                partners.map((part) => {
                    partners.push(part)
                })
                setStreamPage((prevState) => ({ ...prevState, partners: partners }))
            }
        } catch (error) {
            alert(error.message);
        }
    };
    const getPartnersList = async () => {
        // const partners = fillPartners();
        // document.documentElement.style.setProperty('--partners-list-size', `${partners?.length * 20}vw`);
        // partners?.map((partner) => {
        //     partners.push(partner)
        // })
        // setPartners(partners);

        const partners = [];
        try {
            const response = await axios.get(`${endPoint}/api/partners/`);
            if ('error' in response.data) {
                alert(response.data.error);
                return
            }
            if (response.data && response.data.length > 0) {

                for (let i = 0; i < 6;) {
                    response.data.map((partner) => {
                        partners.push(partner)
                        i++
                    })
                }
                document.documentElement.style.setProperty('--partners-list-size', `${partners.length * 20}vw`);
                partners.map((partner) => {
                    partners.push(partner)
                })
                setPartners(partners);
            }
        } catch (error) {
            alert(error.message);
        }

    };
    const getHeaderLinks = async () => {
        // setLinks(fillLinks());

        try {
            const response = await axios.get(`${endPoint}/api/social_media/`);
            if ('error' in response.data) {
                alert(response.data.error);
                return
            }
            setLinks(response.data)
        } catch (error) {
            alert(error.message);
        }
    };
    const getContent = async () => {
        // fillContent();
        try {
            const response = await axios.get(`${endPoint}/api/languages/`);
            if ('error' in response.data) {
                alert(response.data.error);
                return
            }
            setContent(response.data);
        } catch (error) {
            alert(error.message);
        }
    };
    const spin = async (promocode) => {
        try {
            const response = await axios.post(`${endPoint}/api/slots/random/`, { promocode: promocode }, requestOptions);
            if ('error' in response.data) {
                alert(response.data.error);
                return
            }

            setModaleType(null);
            const wheel = document.getElementsByTagName("canvas")[0];
            wheel.classList.add("spinn");
            setRollIsActive(true);
            document.documentElement.style.setProperty("--win", `${slots.indexOf(slots.find((slot) => slot.name === response.data.name))}`);
            setTimeout(() => {
                setPrize(response.data);
                setModaleType('prize');
            }, 6000);
            setTimeout(() => {
                wheel.classList.remove("spinn");
                setRollIsActive(false);
            }, 10000);

        } catch (error) {

            if (error.response.data.code === "token_not_valid")
                return refresh();

            if (error.response.data.error) {

                const notifi = {
                    type: notifiTypes.error,
                    time: 2000,
                    text: error.response.data.error,
                }

                return addNotifi(notifi);
            }


            alert(error.message);
        }
    };

    const getWinners = async () => {
        // fillwinners();

        const winners = [];

        try {
            const response = await axios.get(`${endPoint}/api/slots/winners/`);
            if ('error' in response.data) {
                alert(response.data.error);
                return
            }
            
            if (response.data && response.data.length > 0) {
                for (let i = 0; winners.length * 270 < window.innerWidth;) {
                    response.data.forEach(winner => {
                        winners.push(winner);
                    })
                }
                document.documentElement.style.setProperty('--winners-list-size', `${winners?.length * 270}px`);
                winners.forEach(winner => {
                    winners.push(winner);
                })
                setWinners(winners);
            }

        } catch (error) {
            alert(error.message);
        }
    };
    const getSlots = async () => {
        // fillSlots();

        try {
            const response = await axios.get(`${endPoint}/api/slots/list/`);
            if ('error' in response.data) {
                alert(response.data.error);
                return
            }
            setSlots(response.data)
        } catch (error) {
            alert(error.message);
        }

    };
    const getToken = async (user_data = user) => {
        try {
            const response = await axios.post(`${endPoint}/api/users/auth/telegram/`, user_data);
            if ('error' in response.data) {
                alert(response.data.error);
                return
            }
            setAccessToken(response.data.access_token);
            setRefreshToken(response.data.refresh_token);
            localStorage.setItem('accessToken', response.data.access_token);
            localStorage.setItem('refreshToken', response.data.refresh_token);
        } catch (error) {
            alert(error.message);
        }
    };
    const refresh = async () => {
        try {
            const response = await axios.post(`${endPoint}/api/users/token/refresh/`, { refresh: refreshToken });
            if ('error' in response.data) {
                alert(response.data.error);
                return
            }
            setAccessToken(response.data.access);
            setRefreshToken(response.data.refresh);
            localStorage.setItem('accessToken', response.data.access);
            localStorage.setItem('refreshToken', response.data.refresh);
        } catch (error) {
            logout();
        }
    };
    const getDetails = async () => {
        try {
            const response = await axios.get(`${endPoint}/api/users/detail/${user.id}/`, requestOptions);
            if ('error' in response.data) {
                alert(response.data.error);
                return
            }
            setUser((prevState) => {
                return {
                    ...prevState,
                    ...response.data
                }
            })
        } catch (error) {
            console.log(error)
            alert(error.message);
        }
    };
    const userHistory = async () => {
        try {
            const response = await axios.get(`${endPoint}/api/users/spin-history/`, requestOptions);
            if ('error' in response.data) {
                alert(response.data.error);
                return
            }
            setUser((prevState) => {
                return {
                    ...prevState,
                    history: response.data.reverse()
                }
            })
        } catch (error) {
            if (error.response.data.code === "token_not_valid") {
                refresh();
                return
            }

            alert(error.message);
        }
    };

    const logout = () => {
        localStorage.setItem("accessToken", null);
        localStorage.setItem("refreshToken", null);
        localStorage.setItem("user", null);
        setUser(null);
        setAccessToken(null);
        setRefreshToken(null);
        setModaleType(null);
    }

    useEffect(() => {
        try {
            setUser(JSON.parse(localStorage.getItem("user")));
        }
        catch {
            setUser(null);
        }
    }, []);

    useEffect(() => {
        if (promocode && accessToken)
            spin(promocode.value);
    }, [accessToken, promocode]);

    useEffect(() => {
        notifiListRef.current = notifiList
    }, [notifiList]);

    useEffect(() => {
        notifiQueueRef.current = notifiQueue
    }, [notifiQueue]);

    return <Context.Provider value={{
        streamPage,
        setStreamPage,
        getStreamerPage,

        partnersList,
        getPartnersList,

        streamersList,
        getStreamersList,

        mediaLinks,
        getHeaderLinks,

        content,
        getContent,

        language,
        setLanguage,

        disposition,
        setDisposition,

        winners,
        getWinners,

        slots,
        getSlots,

        modaleType,
        setModaleType,

        user,
        setUser,
        // getUser,

        accessToken,

        getToken,
        getDetails,

        logout,

        spin,
        prize,

        userHistory,

        promocode,
        setPromocode,

        rollIsActive,

        startTimer,

        notifiList,
        setNotifiList,

        notifiQueue,
        setNotifiQueue,

        notifiTypes,
        addNotifi,


    }} >{children}</Context.Provider>
}

const useData = () => {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error('useData must be used within a SocketProvider');
    }
    return context;
};


export { ContextProvider, useData };